import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Fact } from 'src/app/store/fact/fact.model';
import { environment as env } from '../../../environments/environment';
import { FactQuery } from '../models/fact-query.model';

@Injectable({
  providedIn: 'root'
})
export class FactService {
  constructor(private httpClient: HttpClient) { }

  public get(query: FactQuery): Observable<any> {
    let params = new HttpParams();
    Object.keys(query).forEach(key => {
      if (key && key !== 'type') {
        params = params.append(key, query[key]);
      }
    });

    return this.httpClient.get(`${env.apiUrl}/facts`, { params });
  }
  
  public create(fact: Fact): Observable<any> {
    return this.httpClient.post(`${env.apiUrl}/facts`, this.toDto(fact));
  }

  public update(fact: Fact): Observable<any> {
    return this.httpClient.put(`${env.apiUrl}/facts/${fact.id}`, this.toDto(fact));
  }

  public delete(fact: Fact): Observable<any> {
    return this.httpClient.delete(`${env.apiUrl}/facts/${fact.id}`);
  }

  public getNomenclatures() {
    return [
      //{
      //  type: 'A',
      //  label: "Atteintes à l'intégrité physique",
      //},
      {
        type: 'A1',
        label: "Atteintes volontaires à l'intégrité physique",
        example: "Coups et blessures, crachats sur une personne, visées laser dans les yeux, jets de projectiles avec victimes…",
      },
      {
        type: "A11",
        label: "Jets de projectiles avec victime(s)",
        example: "Jets de pierre, de ballast, de bouteille…",
      },
      {
        type: "A2",
        label: "Atteintes volontaires non physiques",
        example: "Insultes, injures, menaces, outrages à agent…",
      },
      //{
      //  type: "B",
      //  label: "Atteintes sexuelles et sexistes",
      //},
      {
        type: "B1",
        label: "Viols ou tentatives de viol",
      },
      {
        type: "B2",
        label: "Autres atteintes sexuelles avec contact",
        example: "Attouchements sexuels, agissements de frotteurs…",
      },
      {
        type: "B3",
        label: "Atteintes sexuelles et sexistes sans contact",
        example: "Exhibition, voyeurisme, outrage sexiste…",
      },
      {
        type: "B31",
        label: "Outrages sexistes verbalisés",
        example: "Sifflements, gestes/bruits obscènes, propositions sexuelles, questions intrusives/commentaires dégradants, suivre une personne de manière insistante…",
      },
      {
        type: "C",
        label: "Vols accompagnés de violence et/ou menaces",
        example: "Vols à l'arraché…",
      },
      //{
      //  type: "D",
      //  label: "Atteintes aux biens sans atteintes aux personnes",
      //},
      {
        type: "D1",
        label: "Vols simples",
        example: "Vols à la tire, agissemements des pickpockets, vols à l'étalage, vols de matériel sans effraction…",
      },
      {
        type: "D2",
        label: "Dégradations / Dommages",
        example: "Destructions, déprédations, bris de glace, tags/graffitis, jets de projectiles avec bris sans victime, incendies…",
      },
      {
        type: "D21",
        label: "Tags / Graffitis",
        example: "Tags, graffs, graffitis, gravures, fresques…",
      },
      {
        type: "D22",
        label: "Jets de projectiles avec bris sans victimes",
        example: "Jet de pierre, de ballast, de bouteille…",
      },
      {
        type: "D23",
        label: "Incendies",
        example: "Incendies de poubelle, de devanture…",
      },
      {
        type: "D3",
        label: "Vols avec effraction",
        example: "Vols d'un bien après avoir forcé la serrure…",
      },
      //{
      //  type: "E",
      //  label: "Atteintes visant à perturber le service de transport",
      //},
      {
        type: "E1",
        label: "Entraves à la circulation des véhicules",
        example: "Personnes sur les voies, dépôts d'objets, voitures garées devant le bus, jets de projectiles sans bris ni victime, visées laser…",
      },
      {
        type: "E11",
        label: "Jets de projectiles sans bris ni victime",
        example: "Jets de pierre, de ballast, de bouteille…",
      },
      {
        type: "E12",
        label: "Visées laser",
        example: "Utilisation de faisceau laser pour gêner autrui…",
      },
      {
        type: "E2",
        label: "Manipulation des équipements",
        example: "Faire obstacle à la fermeture des portes, ouvrir les portes d'accès après le signal de départ, actionner le signal d'alarme et les purges de portes sans motif légitime…",
      },
      {
        type: "E3",
        label: "Non-respect des consignes de vigilance",
        example: "Bagages ou colis délaissés, fausses alertes…",
      },
      //{
      //  type: "F",
      //  label: "Atteintes d'ordre comportemental",
      //},
      {
        type: "F1",
        label: "Actes contraires aux règles de police des transports",
        example: "Cracher, uriner, souiller les espaces, troubler la tranquillité d'autrui par des bruits ou des tapages, être en état d'ivresse manifeste, fumer en dehors des espaces autorisés, porter ou transporter une arme à feu, introduire un aminal sans autorisation…",
      },
      {
        type: "F2",
        label: "Rixes",
        example: "Bagarres, altercations…",
      },
      {
        type: "F3",
        label: "Infractions liées à la vente illicite",
        example: "Vente à la sauvette, vente et/ou consommation de produits stupéfiants…",
      },
    ]
  }

  private toDto(fact: Fact) {
    return {
      date: fact.date,
      time: fact.time,
      networkId: this.toNumber(fact.networkId),
      town: fact.town,
      transport: fact.transport,
      stop: fact.stop,
      line: fact.line,
      priorityZone: fact.priorityZone,
      nomenclature: fact.nomenclature,
      cause: fact.cause,
      serviceImpact: fact.serviceImpact,
      offerModification: fact.offerModification,
      departmentIntervention: fact.departmentIntervention,
      policeIntervention: fact.policeIntervention,
      emergencyIntervention: fact.emergencyIntervention,
      complaint: fact.complaint,
      weapon: fact.weapon,
      persons: fact.persons,
      goods: fact.goods,
    }
  }

  private toNumber(value: any) {
    return typeof value === 'string'
      ? parseInt(value)
      : value;
  }
}