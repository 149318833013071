import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Network } from 'src/app/store/network/network.model';
import { environment as env } from '../../../environments/environment';
import { NetworkQuery } from '../models/network-query.model';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<any> {
    return this.httpClient.get(`${env.apiUrl}/networks/all`);
  }

  get(query: NetworkQuery): Observable<any> {
    let params = new HttpParams();
    if (query.page) {
      params = params.append('page', query.page);
    }
    if (query.limit) {
      params = params.append('limit', query.limit);
    }
    if (query.name) {
      params = params.append('name', query.name);
    }
    if (query.group) {
      params = params.append('group', query.group);
    }
    if (query.authority) {
      params = params.append('authority', query.authority);
    }

    return this.httpClient.get(`${env.apiUrl}/networks`, { params });
  }

  create(network: Network): Observable<any> {
    return this.httpClient.post(`${env.apiUrl}/networks`, {
      name: network.name,
      group: network.group,
      authority: network.authority
    });
  }

  update(network: Network): Observable<any> {
    return this.httpClient.put(`${env.apiUrl}/networks/${network.id}`, {
      name: network.name,
      group: network.group,
      authority: network.authority,
      transports: network.transports,
      towns: network.towns
    });
  }

  delete(network: Network): Observable<any> {
    return this.httpClient.delete(`${env.apiUrl}/networks/${network.id}`);
  }
}

