import { formatDate } from "@angular/common";
import { NetworkHelper } from "src/app/core/helpers/network.helper";
import { FactGood } from "src/app/store/fact/fact-good.model";
import { FactPerson } from "src/app/store/fact/fact-person.model";
import { Fact } from "src/app/store/fact/fact.model";

export class CSVHelper {

    private static factHeaders = [
        'Identifiant',
        'Fait Nomenclature 1',
        'Fait Nomenclature 2',
        'Fait Nomenclature 3',
        'Fait Nomenclature 4',
        'Date',
        'Heure',
        'Commune',
        'Code postal',
        'Mode de transport',
        'code INSEE',
        'Station / arrêt',
        'Ligne',
        'Zone de sécurité prioritaire',
        'Impact sur le service relevé',
        "Modification de l'offre",
        'Intervention des services internes',
        "Intervention des forces de l'ordre",
        'Intervention des secours',
        "Dépôt d'une plainte",
        'Incident de contrôle',
        'Différend de circulation',
        'Ébriété',
        'Trouble du comportement',
        'Interposition / Assistance',
        'Rappel du règlement',
        'Problème tarifaire',
        'Violence urbaine',
        'Représailles',
        'Fait commis avec une arme',
    ];

    private static personHeaders = [
        'Identifiant',
        "Type d'implication",
        'Qualité',
        'Fonction',
        'Sexe',
        'Nombre de personnes',
        "Délivrance d'ITT",
        "Nombre d'ITT par victime",
    ]

    private static goodHEaders = [
        'Identifiant',
        'Qualité du bien',
        'Type de bien',
    ]

    public static toCSV(facts: Fact[]) {
        let factContent = `${CSVHelper.factHeaders.join('|')}\n`;
        let personContent = `${CSVHelper.personHeaders.join('|')}\n`;
        let goodContent = `${CSVHelper.goodHEaders.join('|')}\n`;
        
        if (!facts || !facts.length) {
            return {
                factContent,
                personContent,
                goodContent
            }
        }

        facts.forEach((_fact) => {
            const data = CSVHelper.toFactLine(_fact);
            factContent = factContent.concat(`${data.factLine}\n`);
            if (data.personLines.length) {
                personContent = personContent.concat(`${data.personLines.join('\n')}\n`);
            }
            if (data.goodLines.length) {
                goodContent = goodContent.concat(`${data.goodLines.join('\n')}\n`);
            }
        });

        return {
            factContent,
            personContent,
            goodContent
        }
    }

    private static toFactLine(fact: Fact) {
        const factTown = NetworkHelper.parseTown(fact.town);
        const factLine = [
          fact.id,
          fact.nomenclature,
          '',
          '',
          '',
          CSVHelper.toDate(fact.date),
          CSVHelper.toTime(fact.date),
          factTown.name,
          factTown.postalCode,
          fact.transport,
          factTown.code,
          fact.stop,
          fact.line,
          '',
          CSVHelper.toConsequence(fact.serviceImpact),
          CSVHelper.toConsequence(fact.offerModification),
          CSVHelper.toConsequence(fact.departmentIntervention),
          CSVHelper.toConsequence(fact.policeIntervention),
          CSVHelper.toConsequence(fact.emergencyIntervention),
          CSVHelper.toConsequence(fact.complaint),
          CSVHelper.toCause(fact.cause, 'Incident de contrôle'),
          CSVHelper.toCause(fact.cause, 'Différend de circulation'),
          CSVHelper.toCause(fact.cause, 'Ébriété'),
          CSVHelper.toCause(fact.cause, 'Trouble du comportement'),
          CSVHelper.toCause(fact.cause, 'Interposition / Assistance'),
          CSVHelper.toCause(fact.cause, 'Rappel du règlement'),
          CSVHelper.toCause(fact.cause, 'Problème tarifaire'),
          CSVHelper.toCause(fact.cause, 'Violence urbaine'),
          CSVHelper.toCause(fact.cause, 'Représailles'),
          CSVHelper.toCause(fact.cause, 'Fait commis avec une arme'),
        ].join('|');

        let personLines = [];
        if (fact.persons) {
            fact.persons.forEach((_person) => {
                const personLine = CSVHelper.toPersonLine(fact.id, _person);
                if (personLine) {
                    personLines.push(personLine);
                }
            });
        }

        let goodLines = [];
        if (fact.goods) {
            fact.goods.forEach((_good) => {
                const goodLine = CSVHelper.toGoodLine(fact.id, _good);
                if (goodLine) {
                    goodLines.push(goodLine);
                }
            });
        }

        return {
            factLine,
            personLines,
            goodLines,
        }
    }

    private static toPersonLine(factId: number, person: FactPerson): string {
        return [
            factId,
            person.type,
            person.quality,
            person.function,
            person.gender,
            person.count,
            '',
            ''
        ].join('|');
    }

    private static toGoodLine(factId: number, good: FactGood): string {
        return [
            factId,
            good.quality,
            good.type
        ].join('|');
    }

    private static toDate(date: Date) {
        return formatDate(date, 'dd/MM/yyyy', 'fr');
    }

    private static toTime(date: Date) {
        return formatDate(date, 'HH:mm', 'fr');
    }

    private static toCause(currentCause: string, expectedCause: string) {
        if (currentCause == 'Inconnue') {
            return '';
        }
        else if (currentCause == expectedCause) {
            return 1;
        }
        else {
            return 0;
        }
    }

    private static toConsequence(consequence: boolean | null) {
        if (consequence == null) {
            return 'Inconnu';
        }
        return consequence ? 'Oui' : 'Non';
    }
}